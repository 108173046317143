@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

button:focus {
  outline: none !important;
}
html,
body,
div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Late", sans-serif !important;
}
button, input {
  font-family: "Late", sans-serif !important;
}
* {
  box-sizing: border-box;
}
.Mui-selected {
  background: rgba(0, 147, 245, 0.08) !important;
}

div#modal-anticipation-send-xml {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: auto;
  max-height: 500px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 10px rgba(0,0,0,.4);
  padding: 40px;
  label#label-xml-file {
    padding:15px;
    border-radius:5px 0px;
    border:1px dashed #3757a1;
    width:100%;
    font-weight:900;
    color:#3757a1;
    text-align:center;
    margin-top:10px;
    cursor:pointer;
    transition:300ms;
    small {
      display:block;
      font-size:11px;
    }
    &:hover {
      background:#f7f7f7;
    }
  }
  input#xml-file {
    display:none;
  }
}
/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius:30px 4px 30px 6px;
  padding:15px;
  background:#fff;
  color:#999;
  border-right:20px solid #3757a1 !important;
  min-height: 70px;
  text-align: center;
  font-weight: 900;
}
.Toastify__toast--dark {
  border-color:#222;
  color:#222;
}
.Toastify__toast--default {
  border-color:#c9c9c9;
  color:#c9c9c9;
}
.Toastify__toast--info {
  border-color:#0178ff;
  color:#0178ff;
}
.Toastify__toast--success {
  border-color:#2bc76c;
  color:#2bc76c;
}
.Toastify__toast--warning {
  border-color:#3757a1;
  color:#3757a1;
}
.Toastify__toast--error {
  border-color:#d41010;
  color:#d41010;
}
/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color:#999;
}
.Toastify__close-button--default {
  color:#999;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  background:#3757a1;
}